<mgl-map
  [class]="classes$ | async"
  [style]="style$ | async"
  [zoom]="[zoom]"
  [center]="center"
  [maxZoom]="26"
  (mapLoad)="mapLoaded($event)"
>
  @if ((mapReady$ | async) === true) {
    <planning-layer-management></planning-layer-management>
  }
</mgl-map>
<planning-context-bar></planning-context-bar>
@if (map) {
  <mydendra-scalebar
    [map]="map"
    [class.scalebar--panel-open]="leftPanelOpen$ | async"
  ></mydendra-scalebar>
}
<router-outlet></router-outlet>
