import { CommonModule, LocationStrategy } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { getFeatureColor } from '@dendra/utils';
import { PlanningLocationService } from '@mydendra/planning/states/location';
import { SidePanelService } from '@mydendra/planning/states/side-panel.service';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { SharedPipesModule } from '@shared/pipes';
import { filter, map, switchMap, tap } from 'rxjs';
import { PlanningTargetAreaService } from '../states/target-area';

@Component({
  templateUrl: './location-detail-panel.component.html',
  styleUrls: ['./location-detail-panel.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
})
export class LocationDetailPanelComponent {
  private locationService = inject(PlanningLocationService);
  private panelService = inject(SidePanelService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private targetAreaService = inject(PlanningTargetAreaService);
  locationStrategy = inject(LocationStrategy);

  location$ = this.route.paramMap.pipe(
    switchMap(params =>
      this.locationService.base.getOrSelect(+params.get('aoi')),
    ),
    tap(location => this.locationService.base.setActive(location.id)),
    map(location => ({
      ...location,
      ...this.locationService.getActivity(location.id),
    })),
  );

  numTargetAreas$ = this.location$.pipe(
    filter(Boolean),
    switchMap(loc =>
      this.targetAreaService.base.query.selectCount(
        ta => ta.location == loc.id && ta.visibility === 'shared',
      ),
    ),
  );

  closePanel() {
    this.panelService.setLeftPanel(null);
  }

  getColor = getFeatureColor;

  handleBack() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  openEditPanel() {
    this.router.navigate(['edit'], { relativeTo: this.route });
  }
}
