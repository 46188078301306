import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ComponentsModule } from '@mydendra/components/components.module';
import { MapControlService } from '@services/map-control.service';
import { LocationCollectionService } from '@services/states/location-collection';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { forkJoin, switchMap, tap } from 'rxjs';
import { PlanningLocationService } from '../states/location';
import {
  LeftPanelOptions,
  SidePanelService,
} from '../states/side-panel.service';

@Component({
  templateUrl: './planning-page.component.html',
  styleUrls: ['../../shared/styles/map-page.scss'],
  providers: [PlanningLocationService, MapControlService],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    ComponentsModule,
  ],
})
export class PlanningPageComponent implements OnInit, OnDestroy {
  sidePanelService = inject(SidePanelService);
  private locationCollectionService = inject(LocationCollectionService);
  private locationService = inject(PlanningLocationService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  LEFT_PANEL_OPTIONS = LeftPanelOptions;

  ngOnDestroy(): void {
    // unmount the left side panel when navigating away from this app
    this.sidePanelService.setLeftPanel(null);
  }

  $collection = this.route.paramMap.pipe(
    switchMap(params =>
      this.locationCollectionService.getOrSelect(+params.get('lc')),
    ),
  );

  ngOnInit() {
    this.sidePanelService.setLeftPanel(LeftPanelOptions.AREAS);

    // Populate the store
    this.locationCollectionService
      .getLocationCollections()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();

    this.$collection
      .pipe(
        tap(activeLocationCollection =>
          this.locationCollectionService.setActive(activeLocationCollection.id),
        ),
        switchMap(locationCollection =>
          forkJoin([
            this.locationService.getForLocationCollection(
              locationCollection.id,
            ),
            this.locationService.getActivityForLocationCollection(
              locationCollection.id,
            ),
          ]),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: () => {
          this.router.navigate(['/404']);
        },
      });
  }

  toggleLeftTabSelection(tabName?: LeftPanelOptions) {
    this.sidePanelService.setLeftPanel(tabName);
  }

  setCollection(e: { collection: number }) {
    this.router.navigate(['../', e.collection], { relativeTo: this.route });
  }

  setLocation(e: { collection: number; location: number }) {
    this.router.navigate(['../', `${e.collection}`, 'aoi', e.location], {
      relativeTo: this.route,
    });
  }
}
