import { Injectable, inject } from '@angular/core';
import { Query, Store, StoreConfig, arrayToggle } from '@datorama/akita';
import { LocationService } from '@mydendra/states/location';
import { LocationCollection } from '@services/states/location-collection';
import { combineLatest, map, skipWhile, switchMap } from 'rxjs';

export type PlanningLocationUI = {
  hasPanned: number[];
  visible: number[]; // locations that are visible on the map
};

interface PlanningLocationUIState extends PlanningLocationUI {}

@StoreConfig({ name: 'planning-location' })
@Injectable({ providedIn: 'root' })
export class PlanningLocationUIStore extends Store<PlanningLocationUIState> {
  constructor() {
    super({
      hasPanned: [],
      visible: [],
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PlanningLocationQuery extends Query<PlanningLocationUIState> {
  protected store: PlanningLocationUIStore;

  constructor() {
    const store = inject(PlanningLocationUIStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class PlanningLocationService {
  protected uiStore = inject(PlanningLocationUIStore);
  uiQuery = inject(PlanningLocationQuery);
  base = inject(LocationService);

  query = this.base.query;

  getForLocationCollection = this.base.getForLocationCollection;
  getActivityForLocationCollection = this.base.getActivityForLocationCollection;
  getActivity = this.base.getActivity;

  selectAllWithActivity(collectionId: LocationCollection['id']) {
    return combineLatest([
      this.query.selectAll({
        filterBy: ({ collection }) => collection === collectionId,
      }),
      this.base.activityQuery.selectLoading().pipe(
        skipWhile(loading => loading),
        switchMap(() => this.base.activityQuery.selectAll({ asObject: true })),
      ),
    ]).pipe(
      map(([locations, activity]) =>
        locations.map(loc => ({
          ...loc,
          ...(activity[loc.id] || {
            first_activity: null,
            last_activity: null,
          }),
        })),
      ),
    );
  }

  togglePannedTo(id: number) {
    return this.uiStore.update(({ hasPanned }) => ({
      hasPanned: arrayToggle(hasPanned, id),
    }));
  }

  toggleVisibility(id: number) {
    return this.uiStore.update(({ visible }) => ({
      visible: arrayToggle(visible, id),
    }));
  }

  setVisibility(ids: number[]) {
    // deduplicate
    ids = Array.from(new Set(ids));
    return this.uiStore.update({ visible: ids });
  }

  hasPanned(id: number) {
    return this.uiQuery.getValue().hasPanned.includes(id);
  }

  selectVisibleIds() {
    return this.uiQuery
      .select('visible')
      .pipe(map(ids => ids.filter(id => this.query.hasEntity(id))));
  }

  selectVisible() {
    return this.uiQuery
      .select('visible')
      .pipe(switchMap(ids => this.query.selectMany(ids)));
  }
}
