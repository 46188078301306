import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedComponentsModule } from '@shared/components';
import { SidePanelService } from '../states/side-panel.service';
import { TargetAreaFormComponent } from '../target-area-form/target-area-form.component';

@Component({
  selector: 'mydendra-target-area-create',
  templateUrl: './target-area-create.component.html',
  imports: [SharedComponentsModule, TargetAreaFormComponent],
})
export class TargetAreaCreateComponent {
  private panelService = inject(SidePanelService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  closePanel() {
    this.panelService.setLeftPanel(null);
  }

  navigateOut() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
