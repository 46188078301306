<mydendra-second-nav [sticky]="false">
  <div class="nav-slot">
    <mydendra-location-selector
      (collectionSet)="setCollection($event)"
      (locationSet)="setLocation($event)"
    ></mydendra-location-selector>
    <a
      class="panel-link"
      [class.panel-link--selected]="
        (sidePanelService.leftPanel$ | async) === LEFT_PANEL_OPTIONS.AREAS
      "
      (click)="toggleLeftTabSelection(LEFT_PANEL_OPTIONS.AREAS)"
      placement="bottom"
    >
      <mydendra-icon size="lg" name="globe-pointer"></mydendra-icon>
      Areas of Interest
    </a>
    <a
      class="panel-link"
      [class.panel-link--selected]="
        (sidePanelService.leftPanel$ | async) ===
        LEFT_PANEL_OPTIONS.COLLECTION_LAYERS
      "
      (click)="toggleLeftTabSelection(LEFT_PANEL_OPTIONS.COLLECTION_LAYERS)"
      placement="bottom"
    >
      <mydendra-icon size="lg" name="layers-stack"></mydendra-icon>
      Planning Layers
    </a>
  </div>
  <div
    *ifHasFlag="'show-resos-beta-tags'"
    class="flex-fill h-100 d-flex justify-content-end align-items-center p-3"
  >
    <div>
      <mydendra-status
        text="Beta"
        type="info"
        theme="outline"
        size="sm"
      ></mydendra-status>
    </div>
  </div>
</mydendra-second-nav>
<div class="planning__main">
  <router-outlet></router-outlet>
</div>
