<div class="sticky-panel-header">
  <mydendra-side-panel-nav
    (closePanel)="closePanel()"
    heading="Create Target Area"
  >
  </mydendra-side-panel-nav>
</div>

<planning-target-area-form
  (finished)="navigateOut()"
></planning-target-area-form>
