import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SharedComponentsModule } from '@shared/components';
import { PlanningContextBarService } from '../states/context-bar.service';
import { SidePanelService } from '../states/side-panel.service';

@Component({
  selector: 'planning-context-bar',
  templateUrl: './context-bar.component.html',
  styleUrls: ['./context-bar.component.scss'],
  imports: [CommonModule, SharedComponentsModule],
})
export class PlanningContextBarComponent {
  private contextBarService = inject(PlanningContextBarService);
  panelService = inject(SidePanelService);

  contextBar$ = this.contextBarService.contextBar$;
}
