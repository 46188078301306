import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectEmptyStateComponent } from '@mydendra/components/project-empty-state/project-empty-state.component';
import { AreaScorecardCreateUpdateComponent } from './area-scorecard-create-update/area-scorecard-create-update.component';
import { AreaScorecardDeleteComponent } from './area-scorecard-delete/area-scorecard-delete.component';
import { LocationDetailPanelComponent } from './location-detail-panel/location-detail-panel.component';
import { LocationListPanelComponent } from './location-list-panel/location-list-panel.component';
import { PlanningMapComponent } from './map/map.component';
import { PlanningPageComponent } from './planning-page/planning-page.component';
import { PlanningRationaleComponent } from './planning-rationale/planning-rationale.component';
import { PlanningSidePanelComponent } from './planning-side-panel/planning-side-panel.component';
import { TargetAreaCreateComponent } from './target-area-create/target-area-create.component';
import { PlanningTargetAreaListComponent } from './target-area-list/target-area-list.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectEmptyStateComponent,
  },
  {
    path: ':lc',
    component: PlanningPageComponent,
    children: [
      {
        path: '',
        component: PlanningMapComponent,
        children: [
          {
            path: '',
            component: PlanningSidePanelComponent,
            children: [
              {
                path: '',
                component: LocationListPanelComponent,
              },
              {
                // needed to be pulled out up here to avoid nesting creation form inside the location detail panel
                path: 'aoi/:aoi/ta/create',
                component: TargetAreaCreateComponent,
              },
              {
                path: 'aoi/:aoi',
                component: LocationDetailPanelComponent,
                children: [
                  {
                    path: 'ta',
                    component: PlanningTargetAreaListComponent,
                  },
                  {
                    path: '',
                    redirectTo: 'ta',
                    pathMatch: 'full',
                  },
                  {
                    path: 'rationale',
                    children: [
                      {
                        path: '',
                        component: PlanningRationaleComponent,
                      },
                      {
                        path: 'create',
                        component: AreaScorecardCreateUpdateComponent,
                      },
                      {
                        path: 'edit/:id',
                        component: AreaScorecardCreateUpdateComponent,
                      },
                      {
                        path: 'delete/:id',
                        component: AreaScorecardDeleteComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlanningRoutingModule {}
