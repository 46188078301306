<form [formGroup]="form" (ngSubmit)="submitTargetArea()">
  <label class="form-label" for="area-name">Target Area Name</label>
  <input
    id="area-name"
    formControlName="name"
    type="text"
    class="form-control"
    placeholder="The name of this target area"
    required
    autofocus
  />
  @if (errors.name) {
    <div class="alert alert-danger">
      {{ errors.name }}
    </div>
  }

  <label class="form-label" for="area-type" class="mt-xs">Type</label>
  <ng-select
    id="area-type"
    formControlName="type"
    [items]="targetAreaTypes"
    bindLabel="name"
    bindValue="id"
    placeholder="Select a type"
    [clearable]="false"
    required
  ></ng-select>

  <div class="d-flex mt-2">
    <mydendra-button
      type="light"
      (click)="exitForm()"
      text="Cancel"
    ></mydendra-button>
    <mydendra-button
      type="primary"
      class="ms-auto"
      text="Save"
      icon="save"
      button_type="submit"
    ></mydendra-button>
  </div>
</form>
