import { Injectable, inject } from '@angular/core';
import { Query, Store, StoreConfig, arrayToggle } from '@datorama/akita';
import { TargetAreaService } from '@mydendra/states/target-area';
import { map, switchMap } from 'rxjs';

export type PlanningTargetAreaUI = {
  visible: number[]; // target areas that are visible on the map
};

interface PlanningTargetAreaUIState extends PlanningTargetAreaUI {}

@StoreConfig({ name: 'planning-target-area' })
@Injectable({ providedIn: 'root' })
export class PlanningTargetAreaUIStore extends Store<PlanningTargetAreaUIState> {
  constructor() {
    super({
      visible: [],
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PlanningTargetAreaQuery extends Query<PlanningTargetAreaUIState> {
  protected store: PlanningTargetAreaUIStore;

  constructor() {
    const store = inject(PlanningTargetAreaUIStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class PlanningTargetAreaService {
  protected uiStore = inject(PlanningTargetAreaUIStore);
  uiQuery = inject(PlanningTargetAreaQuery);
  base = inject(TargetAreaService);

  query = this.base.query;

  toggleVisibility(id: number) {
    return this.uiStore.update(({ visible }) => ({
      visible: arrayToggle(visible, id),
    }));
  }

  setVisibility(ids: number[]) {
    this.uiStore.update({ visible: ids });
  }

  selectVisibleIds() {
    return this.uiQuery
      .select('visible')
      .pipe(map(ids => ids.filter(id => this.query.hasEntity(id))));
  }

  selectVisible() {
    return this.uiQuery
      .select('visible')
      .pipe(switchMap(ids => this.query.selectMany(ids)));
  }
}
