import { Injectable, inject } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  QueryConfig,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import {
  NgEntityService,
  NgEntityServiceConfig,
} from '@datorama/akita-ng-entity-service';
import { DRFPaginatedResponse } from '@dendra/utils';
import { switchMap } from 'rxjs';

export type AreaScorecard = {
  id?: number;
  created?: string;
  created_by?: number;
  additional_context: string;
  planning_document_url: string;
  location: number;
};

interface AreaScorecardState
  extends EntityState<AreaScorecard, number>,
    ActiveState<number> {}

@StoreConfig({ name: 'area-scorecard' })
@Injectable({ providedIn: 'root' })
export class AreaScorecardStore extends EntityStore<AreaScorecardState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: (a: AreaScorecard, b: AreaScorecard) =>
    b.created.localeCompare(a.created),
})
export class AreaScorecardQuery extends QueryEntity<AreaScorecardState> {
  protected store: AreaScorecardStore;

  constructor() {
    const store = inject(AreaScorecardStore);

    super(store);
    this.store = store;
  }
}

@NgEntityServiceConfig({ resourceName: 'area-scorecard' })
@Injectable({ providedIn: 'root' })
export class AreaScorecardService extends NgEntityService<AreaScorecardState> {
  protected store: AreaScorecardStore;
  query = inject(AreaScorecardQuery);

  constructor() {
    const store = inject(AreaScorecardStore);

    super(store);
    this.store = store;
  }

  getForLocation = (locationId: number) =>
    this.get<AreaScorecard[]>({
      params: { location: locationId.toString() },
      mapResponseFn: (res: DRFPaginatedResponse<AreaScorecard>) => res.results,
    }).pipe(
      // Fetch from store to ensure correct ordering
      switchMap(() =>
        this.query.selectAll({ filterBy: a => a.location === locationId }),
      ),
    );
}
