<div class="scorecard" [class.scorecard--collapsed]="collapsed">
  <div class="scorecard__header">
    <span>
      {{ scorecard.created | date: 'MMM yyyy' }}
    </span>
    <div class="d-flex">
      @if (scorecard.created_by === (currentUser$ | async).id) {
        <mydendra-button
          type="action"
          icon="vertical-contextual-menu"
          [small]="true"
          (click)="actionsContextMenu.open($event)"
          class="context-menu-button"
          #actionsContextMenu="ngxContextMenu"
          [contextMenu]="contextMenu"
        ></mydendra-button>
      }
      <mydendra-button
        type="action"
        [icon]="collapsed ? 'chevron-down' : 'chevron-up'"
        [small]="true"
        (click)="toggleCollapsed()"
      ></mydendra-button>
    </div>
  </div>
  @if (!collapsed) {
    <div class="scorecard__content">
      {{ scorecard.additional_context }}
      @if (scorecard.planning_document_url) {
        <a [href]="scorecard.planning_document_url" target="_blank">
          <mydendra-button
            text="View Planning Methodology"
            type="light"
            icon="external-link"
            [small]="true"
            [centered]="true"
          ></mydendra-button>
        </a>
      }
    </div>
  }
</div>

<context-menu #contextMenu menuClass="context-menu">
  <ng-template contextMenuItem (execute)="edit()">
    <span class="d-flex align-items-center menu-item">
      <mydendra-icon name="edit" class="text-muted"></mydendra-icon>
      <span>Edit</span>
    </span>
  </ng-template>
  <ng-template contextMenuItem (execute)="delete()">
    <span class="d-flex align-items-center menu-item">
      <mydendra-icon name="bin" class="text-muted"></mydendra-icon>
      <span>Delete</span>
    </span>
  </ng-template>
</context-menu>
