import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getFeatureColor, rotateFeatureClassColor } from '@dendra/utils';
import { BOUNDARY_LAYER_PAINT_PROPS } from '@mydendra/components/boundary-layer/boundary-layer.component';
import { ComponentsModule } from '@mydendra/components/components.module';
import { Location, LocationBoundary } from '@mydendra/states/location';
import {
  FeatureClass,
  FeatureClassService,
} from '@mydendra/states/node-feature-class';
import {
  RenderableSKAIFeature,
  RenderableSKAIFeatureService,
} from '@mydendra/states/renderable-skai-feature';
import { MapControlService } from '@services/map-control.service';
import { TreeNode, TreeService } from '@services/tree.service';
import bbox from '@turf/bbox';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import {
  Observable,
  debounceTime,
  defaultIfEmpty,
  filter,
  forkJoin,
  map,
  switchMap,
} from 'rxjs';
import { PlanningLocationService } from '../states/location';
import { PlanningTargetAreaService } from '../states/target-area';

@Component({
  selector: 'planning-layer-management',
  templateUrl: './planning-layer-management.component.html',
  imports: [CommonModule, ComponentsModule],
})
export class PlanningLayerManagementComponent implements OnInit {
  private mapControlService = inject(MapControlService);
  private treeService = inject(TreeService);
  private renderableSkaiFeatureService = inject(RenderableSKAIFeatureService);
  private locationService = inject(PlanningLocationService);
  private targetAreaService = inject(PlanningTargetAreaService);
  private featureClassService = inject(FeatureClassService);
  private destroyRef = inject(DestroyRef);

  visibleLocations$: Observable<Location[]> =
    this.locationService.selectVisible();

  boundaries$ = this.visibleLocations$.pipe(
    switchMap(locations =>
      forkJoin(
        locations.map(loc => this.locationService.base.getBoundary(loc.id)),
      ).pipe(defaultIfEmpty([] as LocationBoundary[])),
    ),
    map(boundaries =>
      boundaries.map(b => ({
        ...b,
        paintProps: {
          ...BOUNDARY_LAYER_PAINT_PROPS,
          'line-color': getFeatureColor(`location-${b.id}`),
        },
      })),
    ),
  );

  skaiFeatures$: Observable<RenderableSKAIFeature[]> = this.treeService.query
    .selectActiveOfType('SKAIFeature')
    .pipe(
      switchMap(nodes =>
        forkJoin(
          nodes.map(node =>
            this.renderableSkaiFeatureService.getOrSelect(node.objectId),
          ),
        ).pipe(defaultIfEmpty([])),
      ),
    );
  visibleTargetAreaBoundaries$ = this.targetAreaService.selectVisible().pipe(
    switchMap(targetAreas =>
      forkJoin(
        targetAreas.map(targetArea =>
          this.targetAreaService.base.getBoundary(targetArea.id),
        ),
      ).pipe(defaultIfEmpty([])),
    ),
    map(boundaries =>
      boundaries.map(b => ({
        ...b,
        paintProps: {
          ...BOUNDARY_LAYER_PAINT_PROPS,
          'line-color': getFeatureColor(`targetArea-${b.id}`),
        },
      })),
    ),
  );
  featureClasses$ = this.treeService.query
    .selectActiveOfType('FeatureClass')
    .pipe(
      switchMap(nodes =>
        forkJoin(
          nodes.map(node =>
            this.featureClassService
              .getOrSelect(node)
              .pipe(map(fc => appendColorProp(fc, node))),
          ),
        ).pipe(defaultIfEmpty([])),
      ),
    );

  ngOnInit() {
    this.boundaries$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // Even if locations are added at the same time, Akita will emit individual events
        debounceTime(300),
        map(boundaries =>
          boundaries.filter(b => !this.locationService.hasPanned(b.id)).at(-1),
        ),
        filter(Boolean),
      )
      .subscribe(boundary => this.panToBoundary(boundary));

    this.locationService.base.query
      .selectActive()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(Boolean),
        switchMap(loc => this.locationService.base.getBoundary(loc.id)),
      )
      .subscribe(b => this.panToBoundary(b));
  }

  panToBoundary(boundary: LocationBoundary) {
    if (!boundary?.geometry) {
      return;
    }
    const bboxCoords = bbox(boundary.geometry);
    this.mapControlService.map.fitBounds(bboxCoords as BBox2d, {
      padding: 100,
    });
    this.locationService.togglePannedTo(boundary.id);
  }
}

const appendColorProp = (fc: FeatureClass, node: TreeNode) => ({
  ...fc,
  color: rotateFeatureClassColor(
    node.objectId,
    node.extraData.color,
    node.surveyPeriod,
  ),
});
