import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getFeatureColor } from '@dendra/utils';
import { PlanningLocationService } from '@mydendra/planning/states/location';
import { SidePanelService } from '@mydendra/planning/states/side-panel.service';
import { overlapsDateRange } from '@mydendra/utils/dates';
import { LocationCollectionService } from '@services/states/location-collection';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { SharedPipesModule } from '@shared/pipes';
import {
  combineLatest,
  combineLatestWith,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'planning-location-list-panel',
  templateUrl: './location-list-panel.component.html',
  styleUrls: [
    '../../shared/styles/side-panel.scss',
    './location-list-panel.component.scss',
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    SharedComponentsModule,
    SharedDirectivesModule,
  ],
})
export class LocationListPanelComponent {
  private panelService = inject(SidePanelService);
  private locationService = inject(PlanningLocationService);
  private locationCollectionService = inject(LocationCollectionService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  dateTypeChoices = [{ id: 'activity_id', name: 'Active between' }];
  queryParams$ = this.route.queryParams;
  collectionId$ = this.locationCollectionService.query.selectActiveId();
  locations$ = this.collectionId$.pipe(
    switchMap(colId =>
      combineLatest([
        this.locationService.selectAllWithActivity(+colId),
        this.queryParams$,
      ]),
    ),
    map(([locations, params]) =>
      locations.filter(location =>
        overlapsDateRange(
          params.startDate,
          params.endDate,
          location.first_activity,
          location.last_activity,
        ),
      ),
    ),
    shareReplay(1),
  );
  visibleLocations$ = this.locationService.selectVisibleIds();
  headerText$ = this.locationService.query.selectCount().pipe(
    combineLatestWith(this.locations$),
    map(([count, locations]) => {
      if (!locations?.length || !count || locations.length === count) {
        // do not show header if no locations or no count
        // do not show header if all locations are visible
        return 'Areas of Interest';
      }

      return `Areas of Interest (${locations.length}/${count})`;
    }),
    startWith('Areas of Interest'),
  );

  closePanel() {
    this.panelService.setLeftPanel(null);
  }

  toggleLocationVisibility(id: number) {
    this.locationService.toggleVisibility(id);
  }

  getColor = getFeatureColor;

  navigateToLocation(id: number) {
    // Route to the location detail view to see target areas
    this.router.navigate(['aoi/', id], { relativeTo: this.route });
  }

  onDateRangeChange({
    startDate,
    endDate,
    dateType,
  }: {
    startDate: string | null;
    endDate: string | null;
    dateType: string | null;
  }) {
    // Add the date range to the query params
    const queryParams = { startDate, endDate };
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }
}
