import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from '@services/error-handling.service';
import { SharedComponentsModule } from '@shared/components';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap } from 'rxjs';
import {
  AreaScorecard,
  AreaScorecardService,
} from '../states/area-scorecard.service';
import { SidePanelService } from '../states/side-panel.service';

@Component({
  selector: 'planning-area-scorecard-delete',
  templateUrl: './area-scorecard-delete.component.html',
  imports: [CommonModule, SharedComponentsModule],
})
export class AreaScorecardDeleteComponent {
  private panelService = inject(SidePanelService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private errorHandling = inject(ErrorHandlingService);
  private toastr = inject(ToastrService);
  private areaScorecardService = inject(AreaScorecardService);

  scorecard$ = this.route.params.pipe(
    map(params => params.id),
    switchMap(id => this.areaScorecardService.get<AreaScorecard>(+id)),
  );

  closePanel() {
    this.panelService.setLeftPanel(null);
  }

  cancel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  delete(id: AreaScorecard['id']) {
    this.areaScorecardService.delete(id).subscribe({
      next: () => {
        this.toastr.success('Planning rationale note deleted.');
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      error: this.errorHandling.reportErrorAsToast,
    });
  }
}
