import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthEntity } from '@dendra/entity-auth';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { SharedComponentsModule } from '@shared/components';
import { AreaScorecard } from '../states/area-scorecard.service';

@Component({
  selector: 'planning-area-scorecard',
  templateUrl: './area-scorecard.component.html',
  styleUrls: ['./area-scorecard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedComponentsModule, ContextMenuModule],
})
export class AreaScoreCardComponent implements OnInit {
  @Input() scorecard: AreaScorecard;
  @Input() defaultCollapsed = true;

  private authService = inject(AuthEntity);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  currentUser$ = this.authService.query.userProfile$;

  collapsed: boolean;

  ngOnInit() {
    this.collapsed = this.defaultCollapsed;
  }

  edit() {
    this.router.navigate(['edit', this.scorecard.id], {
      relativeTo: this.route,
    });
  }

  delete() {
    this.router.navigate(['delete', this.scorecard.id], {
      relativeTo: this.route,
    });
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
}
