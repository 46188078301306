<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="mb-3">
    <label class="form-label" for="additional_context">Context</label>
    <textarea
      id="additional_context"
      formControlName="additional_context"
      class="form-control"
      placeholder="The rationale for this area of interest"
      autofocus
    ></textarea>
    <div class="form-text d-flex align-items-center">
      <mydendra-icon size="sm" name="info-context"></mydendra-icon>
      <span>Information that led to the planning rationale</span>
    </div>
  </div>

  <div class="mb-3">
    <label class="form-label" for="additional_context"
      >Link to Planning Document</label
    >
    <input
      id="planning_document_url"
      formControlName="planning_document_url"
      class="form-control"
      type="url"
      placeholder="https://example.com"
      size="30"
    />
    @if (form.get('planning_document_url').errors) {
      <span class="body-sm mt-xs text-danger">
        Please provide a URL in the format https://www.example.com
      </span>
    }
    <div class="form-text d-flex align-items-center">
      <mydendra-icon size="sm" name="info-context"></mydendra-icon>
      <span>Optionally provide a URL link to the planning report</span>
    </div>
  </div>

  <div class="d-flex mt-s">
    <mydendra-button
      type="light"
      (click)="cancel()"
      text="Cancel"
    ></mydendra-button>
    <mydendra-button
      type="primary"
      class="ms-auto"
      text="Save"
      icon="save"
      button_type="submit"
      [disabled]="!form.dirty || form.invalid"
    ></mydendra-button>
  </div>
</form>
