@if (contextBar$ | async; as contextBar) {
  @if (contextBar.open) {
    <div
      class="context-container"
      [class.context-container--left-panel-open]="
        panelService.leftPanel$ | async
      "
    >
      <div class="content-group hints">
        @for (help of contextBar.helpContext; track help) {
          <span>
            @if (help.keyboardShortcut) {
              <mydendra-keyboard-key
                [value]="help.keyboardShortcut"
                [light]="true"
              ></mydendra-keyboard-key>
            }
            {{ help.text }}
          </span>
        }
      </div>
    </div>
  }
}
