@if (scorecard$ | async; as scorecard) {
  <p>Do you want to delete this note?</p>
  <div class="d-flex mt-s">
    <mydendra-button
      type="light"
      (click)="cancel()"
      text="Cancel"
    ></mydendra-button>
    <mydendra-button
      type="danger"
      class="ms-auto"
      text="Delete"
      icon="save"
      (click)="delete(scorecard.id)"
    ></mydendra-button>
  </div>
}
