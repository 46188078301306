import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedComponentsModule } from '@shared/components';
import { switchMap } from 'rxjs';
import { AreaScoreCardComponent } from '../area-scorecard/area-scorecard.component';
import { AreaScorecardService } from '../states/area-scorecard.service';
import { PlanningLocationService } from '../states/location';

@Component({
  selector: 'planning-rationale',
  templateUrl: './planning-rationale.component.html',
  styleUrls: ['./planning-rationale.component.scss'],
  imports: [CommonModule, SharedComponentsModule, AreaScoreCardComponent],
})
export class PlanningRationaleComponent {
  private areaScorecardService = inject(AreaScorecardService);
  private locationService = inject(PlanningLocationService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  scorecards$ = this.locationService.base.query
    .selectActiveId()
    .pipe(switchMap(id => this.areaScorecardService.getForLocation(id)));

  addScorecard() {
    this.router.navigate(['create'], { relativeTo: this.route });
  }
}
