import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SidePanelService } from '@mydendra/planning/states/side-panel.service';
import { LocationService } from '@mydendra/states/location';
import { LocationCollectionService } from '@services/states/location-collection';
import { TreeService } from '@services/tree.service';
import { SharedComponentsModule } from '@shared/components';
import {
  BehaviorSubject,
  combineLatestWith,
  filter,
  forkJoin,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'planning-layers-panel',
  templateUrl: './layers-panel.component.html',
  styleUrls: ['./layers-panel.component.scss'],
  imports: [CommonModule, SharedComponentsModule],
})
export class LayersSidePanelComponent implements OnInit {
  private panelService = inject(SidePanelService);
  private locationCollectionService = inject(LocationCollectionService);
  private locationService = inject(LocationService);
  private treeService = inject(TreeService);
  private destroyRef = inject(DestroyRef);

  dataCategoryTree$ = this.treeService.selectTree('DataCategory');
  private activeLocationId$ = this.locationService.query.selectActiveId();
  private activeCollectionId$ =
    this.locationCollectionService.query.selectActiveId();
  locationTree$ = this.activeLocationId$.pipe(
    filter(Boolean),
    switchMap(loc =>
      this.treeService.selectTree('CategoryGroup', `location-${loc}`),
    ),
  );
  private loading = new BehaviorSubject(true);
  loading$ = this.loading.asObservable();

  ngOnInit(): void {
    this.activeCollectionId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(Boolean),
        combineLatestWith(this.activeLocationId$),
        switchMap(([collectionId, locationId]) => {
          this.loading.next(true);
          this.treeService.clear();
          const fetches = [
            this.treeService.fetchRoot(
              'location_collection_categories',
              collectionId,
            ),
          ];
          if (locationId) {
            fetches.push(this.treeService.fetchRoot('location', locationId));
          }
          return forkJoin(fetches);
        }),
      )
      .subscribe(() => this.loading.next(false));
  }

  closePanel() {
    this.panelService.setLeftPanel(null);
  }
}
