import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { PlanningTargetAreaService } from '@mydendra/planning/states/target-area';
import {
  TargetAreaType,
  targetAreaTypes,
  TargetAreaWithBoundary,
} from '@mydendra/states/target-area';
import { NgSelectModule } from '@ng-select/ng-select';
import { ErrorHandlingService } from '@services/error-handling.service';
import { MapControlService } from '@services/map-control.service';
import { SharedComponentsModule } from '@shared/components';
import bbox from '@turf/bbox';
import { LngLatBoundsLike } from 'maplibre-gl';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'planning-target-area-form',
  templateUrl: './target-area-form.component.html',
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    ToastrModule,
    SharedComponentsModule,
  ],
})
export class TargetAreaFormComponent implements OnInit {
  private mapControlService = inject(MapControlService);
  private errorHandling = inject(ErrorHandlingService);
  private toastr = inject(ToastrService);
  private targetAreaService = inject(PlanningTargetAreaService);
  private route = inject(ActivatedRoute);

  @Output() finished = new EventEmitter<null | number>();
  private analyticsService = inject(AnalyticsEntity);

  targetAreaTypes = targetAreaTypes.map(t => ({
    id: t,
    name: new TitleCasePipe().transform(t),
  }));

  errors = { name: '' };
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    type: new FormControl<TargetAreaType>('candidate'),
  });

  ngOnInit() {
    if (this.mapControlService.tentativeDrawnFeature) {
      this.mapControlService.map.fitBounds(
        bbox(this.mapControlService.tentativeDrawnFeature) as LngLatBoundsLike,
        {
          padding: 100,
        },
      );
    }
  }

  exitForm() {
    this.finished.emit(null);
    this.mapControlService.setMapToolMode();
  }

  submitTargetArea() {
    if (!this.form.valid) {
      this.errors.name = this.form.get('name').errors?.required
        ? 'Name is required'
        : '';
      return false;
    }

    const activeLocation = +this.route.snapshot.params.aoi;
    const { geometry } = this.mapControlService.tentativeDrawnFeature;
    const targetAreaData: TargetAreaWithBoundary = {
      ...this.form.getRawValue(),
      location: activeLocation,
      boundary: { geometry },
      visibility: 'shared',
      description: '',
    };

    this.targetAreaService.base.addArea(targetAreaData).subscribe({
      error: this.errorHandling.reportErrorAsToast,
      next: obj => {
        this.targetAreaService.toggleVisibility(obj.id);
        this.exitForm();
        this.toastr.success(`Target Area '${obj.name}' saved.`);
        this.analyticsService.track('Created Target Area', {
          affected_object_id: obj.id,
        });
      },
    });
  }
}
