@let location = location$ | async;
<div class="sticky-panel-header">
  <mydendra-side-panel-nav
    backButtonText="Back"
    (closePanel)="closePanel()"
    (back)="handleBack()"
  >
  </mydendra-side-panel-nav>

  <div class="side-panel__header">
    @if (location) {
      <div class="pr-xs pl-xs d-flex align-items-center">
        <mydendra-color-picker-icon
          class="pr-xs"
          [color]="getColor('location-' + location.id)"
          [interactive]="false"
        ></mydendra-color-picker-icon>
        <h1 class="heading-3 mb-0">{{ location.name }}</h1>
      </div>
      <div class="side-panel__header__detail-row">
        <div class="d-flex flex-column">
          <div class="body-sm name">Area</div>
          <div class="body-sm">{{ location.area | area }}</div>
        </div>
        <div class="d-flex flex-column">
          <div class="body-sm name">Last activity</div>
          <div class="body-sm">
            {{ (location?.last_activity | date: 'yyyy-MM') || '-' }}
          </div>
        </div>
        <div
          class="d-flex flex-column"
          *ifHasFlag="'show-location-classification'"
        >
          <div class="body-sm name">Classification</div>
          <div class="body-sm">
            {{ location.classification | titlecase }}
          </div>
        </div>
      </div>
      <div class="side-panel__header__actions-row">
        <a
          *ifHasFlag="'monitoring-app'"
          class="d-block w-100"
          href="{{
            locationStrategy.prepareExternalUrl('/monitor/area/' + location.id)
          }}"
          target="_blank"
        >
          <mydendra-button
            text="View in Monitoring"
            type="light"
            icon="overview"
            icon_position="left"
            [centered]="true"
            [small]="true"
          ></mydendra-button>
        </a>
      </div>
    }
  </div>
  <ul dendra-tab-nav>
    <li>
      <a dendra-tab-nav-item routerLinkActive="active" routerLink="./ta">
        <mydendra-icon name="globe"></mydendra-icon>
        Target Areas
        <span class="mydendra-badge">{{ numTargetAreas$ | async }} </span>
      </a>
    </li>
    <li>
      <a dendra-tab-nav-item routerLinkActive="active" routerLink="./rationale">
        <mydendra-icon name="compass-drafting"></mydendra-icon>
        Rationale
      </a>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>
