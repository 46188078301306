import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { StylesInteractor } from '@dendra/interactor-styles';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { PlanningContextBarService } from '@mydendra/planning/states/context-bar.service';
import { MapControlToolMode } from '@services/map-control-config';
import { MapControlService } from '@services/map-control.service';
import { SharedComponentsModule } from '@shared/components';
import { LngLatLike, Map } from 'maplibre-gl';
import { map } from 'rxjs';
import { PlanningContextBarComponent } from '../context-bar/context-bar.component';
import { PlanningLayerManagementComponent } from '../planning-layer-management/planning-layer-management.component';
import { SidePanelService } from '../states/side-panel.service';

@Component({
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [PlanningContextBarService],
  imports: [
    CommonModule,
    RouterModule,
    NgxMapLibreGLModule,
    SharedComponentsModule,
    PlanningLayerManagementComponent,
    PlanningContextBarComponent,
  ],
})
export class PlanningMapComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private mapControlService = inject(MapControlService);
  private panelService = inject(SidePanelService);
  private stylesInteractor = inject(StylesInteractor);

  center: LngLatLike;
  zoom?: number;
  map: Map;
  leftPanelOpen$ = this.panelService.leftPanel$.pipe(map(panel => !!panel));

  // This is a workaround for maplibre not having proper typing when being used with with deck layers
  // Ideally, this issue could be solved by setting the map.__deck.cursor_style = map.cursor_style,
  // however, the effort it takes to get the maplibre service to recognize that there is a __deck instance inside of it
  // is too much for this sprint.
  // https://github.com/mapbox/mapbox-gl-draw/issues/907
  classes$ = this.mapControlService.cursorStyle$.pipe(
    map(cursor => `map-cursor--${cursor}`),
  );

  style$ = this.stylesInteractor.selectBaseStyles();
  mapReady$ = this.mapControlService.mapReady$;

  ngOnInit() {
    // set initial map view from lng,lat, and zoom in route
    const params = this.route.snapshot.queryParams;
    if (params.longitude && params.latitude && params.zoom) {
      this.center = [params.longitude, params.latitude];
      this.zoom = params.zoom;
    }
  }

  defaultToolMode: MapControlToolMode = {
    name: 'default',
    availableDrawModes: ['simple_select'],
    showContextBar: false,
    onCreateGeometry: () => {},
    onUpdateGeometry: () => {},
    onRenderGeometry: () => {},
    onMouseMove: () => {},
    onRightClick: () => {},
    onExit: () => {},
  };

  mapLoaded(map: Map) {
    this.map = map;
    this.mapControlService.setup(map, {
      defaultToolMode: this.defaultToolMode,
    });
    this.mapControlService.setMapToolMode(this.defaultToolMode);
  }
}
