import { portalTreeConfig } from '@mydendra/portal/tree-nodes/configs';
import { NodeObjectType, TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';

// We deliberately use the portal tree config as the base config for planning, as most nodes are identical
export const planningTreeConfig: Record<NodeObjectType, TreeNodeTypeConfig> = {
  ...portalTreeConfig,
  CategoryGroup: { ...defaultConfig, urlParamName: null },
  SurveyGroup: {
    ...portalTreeConfig.SurveyGroup,
    // Don't show change maps
    getChildren: node => [...node.children],
  },
};
