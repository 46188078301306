import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { ComponentsModule } from '@mydendra/components/components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { TREE_CONFIG, TreeService } from '@services/tree.service';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { SharedPipesModule } from '@shared/pipes';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AreaScoreCardComponent } from './area-scorecard/area-scorecard.component';
import { AreaScorecardCreateUpdateComponent } from './area-scorecard-create-update/area-scorecard-create-update.component';
import { AreaScorecardDeleteComponent } from './area-scorecard-delete/area-scorecard-delete.component';
import { LayersSidePanelComponent } from './collection-layers-panel/layers-panel.component';
import { PlanningContextBarComponent } from './context-bar/context-bar.component';
import { LocationDetailPanelComponent } from './location-detail-panel/location-detail-panel.component';
import { LocationListPanelComponent } from './location-list-panel/location-list-panel.component';
import { PlanningMapComponent } from './map/map.component';
import { PlanningLayerManagementComponent } from './planning-layer-management/planning-layer-management.component';
import { PlanningPageComponent } from './planning-page/planning-page.component';
import { PlanningRationaleComponent } from './planning-rationale/planning-rationale.component';
import { PlanningRoutingModule } from './planning-routing.module';
import { PlanningSidePanelComponent } from './planning-side-panel/planning-side-panel.component';
import { TargetAreaCreateComponent } from './target-area-create/target-area-create.component';
import { TargetAreaFormComponent } from './target-area-form/target-area-form.component';
import { PlanningTargetAreaListComponent } from './target-area-list/target-area-list.component';
import { planningTreeConfig } from './tree-nodes/configs';

@NgModule({
  imports: [
    CommonModule,
    PlanningRoutingModule,
    ComponentsModule,
    NgxMapLibreGLModule,
    ReactiveFormsModule,
    TooltipModule,
    ContextMenuModule,
    SharedPipesModule,
    NgSelectModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    PlanningMapComponent,
    PlanningPageComponent,
    PlanningSidePanelComponent,
    LocationListPanelComponent,
    PlanningLayerManagementComponent,
    LayersSidePanelComponent,
    PlanningTargetAreaListComponent,
    PlanningContextBarComponent,
    LocationDetailPanelComponent,
    PlanningRationaleComponent,
    AreaScorecardCreateUpdateComponent,
    AreaScorecardDeleteComponent,
    AreaScoreCardComponent,
    TargetAreaFormComponent,
    TargetAreaCreateComponent,
  ],
  providers: [
    { provide: TREE_CONFIG, useValue: planningTreeConfig },
    TreeService,
  ],
})
export class PlanningModule {}
