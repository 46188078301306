@for (sf of skaiFeatures$ | async; track sf) {
  <mydendra-skai-feature-layer [feature]="sf"></mydendra-skai-feature-layer>
}

@for (fc of featureClasses$ | async; track fc) {
  <mydendra-feature-class-layer
    [featureClass]="fc"
    [color]="fc.color"
  ></mydendra-feature-class-layer>
}

@for (boundary of boundaries$ | async; track boundary) {
  <mydendra-boundary-layer
    [boundary]="boundary.geometry"
    [layerId]="'location-' + boundary.id"
    [paintProps]="boundary.paintProps"
  ></mydendra-boundary-layer>
}

@for (boundary of visibleTargetAreaBoundaries$ | async; track boundary) {
  <mydendra-boundary-layer
    [boundary]="boundary.geometry"
    [layerId]="'target-area-' + boundary.id"
    [paintProps]="boundary.paintProps"
  ></mydendra-boundary-layer>
}
