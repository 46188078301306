<div class="sticky-panel-header">
  <mydendra-side-panel-nav
    (closePanel)="closePanel()"
  ></mydendra-side-panel-nav>
  <h2 class="subtitle-sm ext-uppercase">Planning Layers</h2>
</div>
@let loading = loading$ | async;
@let dataCategoryTree = dataCategoryTree$ | async;
@let locationTree = locationTree$ | async;
@if (loading === false) {
  @if (dataCategoryTree?.length) {
    <div class="section">
      @for (node of dataCategoryTree; track node.id) {
        <mydendra-tree-node [node]="node"></mydendra-tree-node>
      }
    </div>
  }
  @for (node of locationTree; track node.id) {
    <mydendra-tree-node [node]="node"></mydendra-tree-node>
  }
} @else {
  <div class="text-center my-3">
    <mydendra-icon
      class="loading-icon"
      name="spinner"
      [spin]="true"
    ></mydendra-icon>
    <div class="body mr-xxs">Loading planning layers...</div>
  </div>
}
