import { Component, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from '@services/error-handling.service';
import { SharedComponentsModule } from '@shared/components';
import { ToastrService } from 'ngx-toastr';
import { filter, map, switchMap } from 'rxjs';
import {
  AreaScorecard,
  AreaScorecardService,
} from '../states/area-scorecard.service';
import { PlanningLocationService } from '../states/location';
import { SidePanelService } from '../states/side-panel.service';

// regex for the url validation. It will match any url that starts with http or https
const urlRegex = /^(https?):\/\//;

@Component({
  selector: 'planning-area-scorecard-create-update',
  templateUrl: './area-scorecard-create-update.component.html',
  imports: [SharedComponentsModule, ReactiveFormsModule],
})
export class AreaScorecardCreateUpdateComponent implements OnInit {
  private panelService = inject(SidePanelService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private errorHandling = inject(ErrorHandlingService);
  private toastr = inject(ToastrService);
  private areaScorecardService = inject(AreaScorecardService);
  private locationService = inject(PlanningLocationService);

  form = new FormGroup({
    additional_context: new FormControl(''),
    planning_document_url: new FormControl('', {
      validators: [Validators.pattern(urlRegex)],
    }),
  });
  scorecard?: AreaScorecard;

  ngOnInit(): void {
    // If editing, populate the form with existing values
    this.route.params
      .pipe(
        map(params => params.id),
        filter(Boolean),
        switchMap(id => this.areaScorecardService.get<AreaScorecard>(+id)),
      )
      .subscribe(scorecard => {
        this.scorecard = scorecard;
        this.form.setValue({
          additional_context: scorecard.additional_context,
          planning_document_url: scorecard.planning_document_url,
        });
      });
  }

  closePanel() {
    this.panelService.setLeftPanel(null);
  }

  cancel() {
    this.router.navigate(['.'], { relativeTo: this.route.parent });
  }

  submit() {
    if (this.form.invalid) {
      this.toastr.error('Please fix the errors before submitting.');
      return;
    }

    if (this.scorecard) {
      this.updateScorecard();
    } else {
      this.createScorecard();
    }
  }
  updateScorecard() {
    this.areaScorecardService
      .update(this.scorecard.id, this.form.getRawValue())
      .subscribe({
        next: () => {
          this.toastr.success('Planning rationale note updated.');
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        },
        error: this.errorHandling.reportErrorAsToast,
      });
  }

  createScorecard() {
    const location = this.locationService.base.query.getActiveId();
    this.areaScorecardService
      .add({ ...this.form.getRawValue(), location })
      .subscribe({
        next: () => {
          this.toastr.success('Planning rationale note added.');
          this.router.navigate(['.'], { relativeTo: this.route.parent });
        },
        error: this.errorHandling.reportErrorAsToast,
      });
  }
}
