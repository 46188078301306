import { inject, Injectable } from '@angular/core';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { BehaviorSubject } from 'rxjs';

export enum LeftPanelOptions {
  COLLECTION_LAYERS = 'collection_layers', // Layers not tied to a location
  AREAS = 'areas', // Location and target areas
}

@Injectable({ providedIn: 'root' })
export class SidePanelService {
  public leftPanel = new BehaviorSubject<LeftPanelOptions>(null);
  public leftPanel$ = this.leftPanel.asObservable();
  private analyticsService = inject(AnalyticsEntity);

  setLeftPanel(panel: LeftPanelOptions) {
    this.analyticsService.track('Setting planning app side panel', {
      panel,
      side: 'left',
    });
    if (panel == this.leftPanel.getValue()) {
      this.leftPanel.next(null);
    } else {
      this.leftPanel.next(panel);
    }
  }
}
