import { CommonModule } from '@angular/common';
import { Component, NgZone, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getFeatureColor } from '@dendra/utils';
import { TargetAreaTypeBadgeComponent } from '@mydendra/components/target-area-type-badge/target-area-type-badge.component';
import {
  MapControlToolMode,
  MapControlToolModeNames,
} from '@services/map-control-config';
import { MapControlService } from '@services/map-control.service';
import { SharedComponentsModule } from '@shared/components';
import { SharedPipesModule } from '@shared/pipes';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import { filter, map, switchMap, tap } from 'rxjs';
import { PlanningLocationService } from '../states/location';
import { PlanningTargetAreaService } from '../states/target-area';

@Component({
  selector: 'planning-target-area-list',
  templateUrl: './target-area-list.component.html',
  styleUrls: ['./target-area-list.component.scss'],
  imports: [
    CommonModule,
    SharedPipesModule,
    SharedComponentsModule,
    TargetAreaTypeBadgeComponent,
  ],
})
export class PlanningTargetAreaListComponent {
  private zone = inject(NgZone);
  private locationService = inject(PlanningLocationService);
  private targetAreaService = inject(PlanningTargetAreaService);
  private mapControlService = inject(MapControlService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  TOOL_MODES = MapControlToolModeNames;

  targetAreas$ = this.locationService.query.selectActiveId().pipe(
    filter(Boolean),
    tap(id =>
      this.locationService.setVisibility([
        ...this.locationService.uiQuery.getValue().visible,
        +id,
      ]),
    ),
    switchMap(id =>
      this.targetAreaService.base.getForLocation(+id).pipe(map(() => id)),
    ),
    switchMap(id => this.targetAreaService.query.selectSharedForLocation(id)),
  );

  visibleTargetAreas$ = this.targetAreaService.selectVisibleIds();

  currentToolMode$ = this.mapControlService.toolMode$.pipe(
    map(toolMode => toolMode.name),
  );

  getColor = getFeatureColor;

  toggleTargetAreaVisibility(id: number) {
    this.targetAreaService.toggleVisibility(id);
  }

  drawTargetAreaPolygonToolMode: MapControlToolMode = {
    name: this.TOOL_MODES.TARGET_AREA,
    availableDrawModes: ['draw_polygon'],
    showContextBar: true,
    onCreateGeometry: e => {
      this.zone.run(() => {
        this.mapControlService.setTentativeFeature(
          e.features[0] as Feature<Polygon | MultiPolygon>,
        );
        this.router.navigate(['create'], { relativeTo: this.route });
      });
    },
    onUpdateGeometry: () => {},
    onRenderGeometry: () => {},
    onMouseMove: () => {},
    onRightClick: () => {},
    onExit: () => {
      this.router.navigate(['./'], { relativeTo: this.route });
    },
  };

  setToolMode(toolMode?: MapControlToolModeNames) {
    if (
      !toolMode ||
      toolMode === this.mapControlService.toolMode.getValue()?.name
    ) {
      this.mapControlService.setMapToolMode(null);
      return;
    }
    this.mapControlService.setMapToolMode(this.drawTargetAreaPolygonToolMode);
  }
}
