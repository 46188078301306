import { Injectable, inject } from '@angular/core';
import { MapControlService } from '@services/map-control.service';
import { Observable, combineLatestWith, map } from 'rxjs';

export type HelpContext = {
  text: string;
  keyboardShortcut?: string;
};

@Injectable()
export class PlanningContextBarService {
  private mapControlService = inject(MapControlService);

  helpContext$: Observable<HelpContext[]> =
    this.mapControlService.drawMode$.pipe(
      map(mode => {
        const helpContext: HelpContext[] = [];
        if (mode !== 'simple_select') {
          helpContext.push({
            keyboardShortcut: 'esc',
            text: 'Cancel',
          });
        }
        if (
          ['draw_polygon', 'cut_polygon', 'draw_line_string'].includes(mode)
        ) {
          helpContext.push({
            keyboardShortcut: 'enter',
            text: 'Save',
          });
        }
        return helpContext;
      }),
    );

  contextBar$ = this.mapControlService.toolMode$.pipe(
    combineLatestWith(this.helpContext$),
    map(([toolMode, helpContext]) => ({
      open: toolMode.showContextBar,
      helpContext,
    })),
  );
}
