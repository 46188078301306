<div class="sticky-panel-header">
  <mydendra-side-panel-nav
    (closePanel)="closePanel()"
    [heading]="headerText$ | async"
  >
    <mydendra-datefilter
      after-headline
      [startDate]="(queryParams$ | async)?.startDate"
      [endDate]="(queryParams$ | async)?.endDate"
      [collectionId]="collectionId$ | async"
      [tooltipText]="
        'Filter areas of interest to those with activity between the selected dates. Ignores areas with no activity at all.'
      "
      (dateRangeChange)="onDateRangeChange($event)"
      [dateTypeChoices]="dateTypeChoices"
    ></mydendra-datefilter>
  </mydendra-side-panel-nav>
</div>

@if (locations$ | async; as locations) {
  @if (locations.length > 0) {
    <div class="area-list">
      @for (location of locations$ | async; track location) {
        <div
          class="area-card"
          [class.area-card--not-visible]="
            !(visibleLocations$ | async).includes(location.id)
          "
          (click)="navigateToLocation(location.id)"
        >
          <div class="area-header">
            <div class="d-flex align-items-center">
              <mydendra-color-picker-icon
                class="pr-xxs"
                [color]="getColor('location-' + location.id)"
                [interactive]="false"
              ></mydendra-color-picker-icon>
              {{ location.name }}
            </div>
            <div class="d-flex">
              <mydendra-button
                type="link"
                [icon]="
                  (visibleLocations$ | async).includes(location.id)
                    ? 'view-eye'
                    : 'view-eye-off'
                "
                [small]="true"
                (click)="
                  toggleLocationVisibility(location.id);
                  $event.stopPropagation()
                "
              ></mydendra-button>
            </div>
          </div>
          <div class="area-body">
            <div class="area-body__left">
              <div class="d-flex flex-column">
                <div class="body-sm">Area</div>
                <div class="body-sm">{{ location.area | area }}</div>
              </div>
              <ng-container *ifHasFlag="'show-location-classification'">
                <div class="d-flex flex-column">
                  <div class="body-sm">Classification</div>
                  <div class="body-sm">
                    {{ location.classification | titlecase }}
                  </div>
                </div>
              </ng-container>
              <div class="d-flex flex-column">
                <div class="body-sm">Dates Active</div>
                @if (location.first_activity) {
                  <div class="d-flex align-items-center">
                    @if (
                      (location.first_activity | date: 'yyyy-MM') ===
                      (location.last_activity | date: 'yyyy-MM')
                    ) {
                      <div class="body-sm">
                        {{ location.last_activity | date: 'yyyy-MM' }}
                      </div>
                    } @else {
                      <div class="body-sm">
                        {{ (location.first_activity | date: 'yyyy-MM') || '-' }}
                      </div>
                      <mydendra-icon
                        name="chevron-right"
                        size="xxs"
                      ></mydendra-icon>
                      <div class="body-sm">
                        {{ (location.last_activity | date: 'yyyy-MM') || '-' }}
                      </div>
                    }
                  </div>
                } @else {
                  <div class="body-sm">No activity</div>
                }
              </div>
            </div>
            <div class="area-body__right">
              <mydendra-button
                type="link"
                icon="chevron-right"
                [small]="true"
                text="Target Areas"
                (click)="navigateToLocation(location.id)"
              ></mydendra-button>
            </div>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="text-muted">No Areas of Interest to display</div>
    </div>
  }
}
