import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HotkeysService } from '@ngneat/hotkeys';
import { MapControlService } from '@services/map-control.service';
import { map, switchMap } from 'rxjs';
import { LayersSidePanelComponent } from '../collection-layers-panel/layers-panel.component';
import { PlanningLocationService } from '../states/location';
import {
  LeftPanelOptions,
  SidePanelService,
} from '../states/side-panel.service';
import { PlanningTargetAreaService } from '../states/target-area';

@Component({
  templateUrl: './planning-side-panel.component.html',
  styleUrls: [
    '../../shared/styles/side-panel.scss',
    './planning-side-panel.component.scss',
  ],
  imports: [CommonModule, RouterModule, LayersSidePanelComponent],
})
export class PlanningSidePanelComponent implements OnInit {
  private locationService = inject(PlanningLocationService);
  private targetAreaService = inject(PlanningTargetAreaService);
  private mapControlService = inject(MapControlService);
  private route = inject(ActivatedRoute);
  private sidePanelService = inject(SidePanelService);
  private hotkeyService = inject(HotkeysService);
  private destroyRef = inject(DestroyRef);

  LEFT_PANEL_OPTIONS = LeftPanelOptions;
  leftSidePanel$ = this.mapControlService.mapReady$.pipe(
    switchMap(() => this.sidePanelService.leftPanel$),
  );
  locationSelected$ = this.locationService.base.query
    .selectActiveId()
    .pipe(map(id => !!id));

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.has('aoi')) {
        this.locationService.base.setActive(+params.get('aoi'));
      } else {
        this.locationService.base.setActive(null);
        this.targetAreaService.setVisibility([]);
      }
    });

    // handles the user exiting their draw mode using the esc key
    this.hotkeyService
      .addShortcut({ keys: 'esc' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.mapControlService.drawMode.value !== 'simple_select') {
          this.mapControlService.setMapToolMode();
        }
      });
  }
}
