@let leftSidePanel = leftSidePanel$ | async;

@let locationSelected = locationSelected$ | async;

<!--
Where to begin...

So! We have a mixture between angular's routing and conditionally rendering components based on the value of `leftSidePanel`.

The problem with this is that Angular's routes aren't registered if they aren't rendered...

If the routes aren't registered, then navigating to one of them that has params won't trigger any subscriptions to route updates (even though the URL changes).

So we need to render the routes, always...

But we also need to not show them when conditionally rendering the side panel components that aren't routes.

The next problem is that the router-outlet doesn't render children, it renders siblings... So we need this hack to hide the next child.
-->
<div
  [style.display]="leftSidePanel ? 'initial' : 'none'"
  class="side-panel side-panel--left"
  [class.side-panel--dark-bg]="
    locationSelected && leftSidePanel === LEFT_PANEL_OPTIONS.AREAS
  "
>
  <router-outlet
    [class.hide-next]="leftSidePanel !== LEFT_PANEL_OPTIONS.AREAS"
  ></router-outlet>

  @if (leftSidePanel === LEFT_PANEL_OPTIONS.COLLECTION_LAYERS) {
    <planning-layers-panel></planning-layers-panel>
  }
</div>
