@for (scorecard of scorecards$ | async; track scorecard; let i = $index) {
  <planning-area-scorecard
    [scorecard]="scorecard"
    [defaultCollapsed]="!!i"
  ></planning-area-scorecard>
}

<div class="actions-container">
  <mydendra-button
    text="Add Planning Note"
    type="light"
    icon="plus"
    (click)="addScorecard()"
    [centered]="true"
    icon_position="left"
  ></mydendra-button>
</div>
