@if (targetAreas$ | async; as targetAreas) {
  @if (targetAreas.length > 0) {
    <div class="area-list">
      @for (targetArea of targetAreas$ | async; track targetArea) {
        <div
          class="area-card"
          [class.area-card--not-visible]="
            !(visibleTargetAreas$ | async).includes(targetArea.id)
          "
        >
          <div
            class="area-header"
            (click)="toggleTargetAreaVisibility(targetArea.id)"
          >
            <div class="d-flex align-items-center">
              <mydendra-color-picker-icon
                class="pr-xxs"
                [color]="getColor('targetArea-' + targetArea.id)"
                [interactive]="false"
              ></mydendra-color-picker-icon>
              {{ targetArea.name }}
            </div>
            <div class="d-flex">
              <mydendra-button
                type="link"
                [icon]="
                  (visibleTargetAreas$ | async).includes(targetArea.id)
                    ? 'view-eye'
                    : 'view-eye-off'
                "
                [small]="true"
                (click)="
                  toggleTargetAreaVisibility(targetArea.id);
                  $event.stopPropagation()
                "
              ></mydendra-button>
            </div>
          </div>
          <div class="area-body">
            <div class="area-body__left">
              <div class="d-flex flex-column">
                <div class="body-sm">Area</div>
                <div class="body-sm">{{ targetArea.area | area }}</div>
              </div>
              <mydendra-target-area-type-badge
                [targetAreaType]="targetArea.type"
              ></mydendra-target-area-type-badge>
            </div>
            <div class="area-body__right">
              <a [href]="'monitor/target/' + targetArea.id" target="_blank">
                <mydendra-button
                  type="link"
                  icon="external-link"
                  [small]="true"
                  text="View in Monitoring"
                ></mydendra-button>
              </a>
            </div>
          </div>
        </div>
      }
    </div>
  } @else {
    <p class="text-center">There are no target areas to display</p>
  }
}

<div class="actions-container">
  <mydendra-button
    [text]="
      (currentToolMode$ | async) === TOOL_MODES.TARGET_AREA
        ? 'Cancel'
        : 'Create Target Area'
    "
    [icon]="
      (currentToolMode$ | async) === TOOL_MODES.TARGET_AREA ? 'cross' : 'plus'
    "
    [type]="
      (currentToolMode$ | async) === TOOL_MODES.TARGET_AREA
        ? 'secondary'
        : 'light'
    "
    (click)="setToolMode(TOOL_MODES.TARGET_AREA)"
    [centered]="true"
    icon_position="left"
  ></mydendra-button>
</div>
